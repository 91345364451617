import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ArticleCard from "./ArticleCard"

const FeaturedArticlesGrid = ({excludeSlug = null}) => {
  const { allStrapiArticle } = useStaticQuery(graphql`query {
    allStrapiArticle(
      sort: {order: ASC, fields: sort}
      filter: {featured: {eq: true}}
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`)  

  return (
    <div className="flex wrap center gap-large">
      {allStrapiArticle.nodes.filter(articleFilter => articleFilter.slug !== excludeSlug).map((article) => (
        <ArticleCard article={article} />
      ))}
    </div>
  )
}

export default FeaturedArticlesGrid
