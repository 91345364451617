import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {api_url} from '../../constants/index';

const BlockMedia = ({ data }) => {
  const isVideo = data.file.mime.startsWith("video")

  return (
    <div className="block-media">
      {isVideo ? (
        <p>TODO video</p>
      ) : (
        // <GatsbyImage
        //   image={getImage(data.file.localFile)}
        //   alt={data.file.alternativeText}
        // />
        <img src={`${api_url}${data.file.url}`} alt="" />
      )}
    </div>
  )
}

export default BlockMedia
