import React from 'react';
import bottomWave from '../../../assets/images/bottom-wave.svg';

const WaveTitle = ({title, margin}) => {
    return (
        <div className={`flex column items-center ${margin ? margin : 'mb-10'}`}>
            <h1 className="mb-3">{title}</h1>
            <img style={{maxWidth: "300px"}} src={bottomWave} alt="" />
        </div>
    )
}

export default WaveTitle;