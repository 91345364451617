import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import BlocksRenderer from "../components/blocks/BlocksRenderer"
import Seo from "../components/Seo"
import {api_url} from '../constants/index';
import WaveTitle from "../components/ui/titles/WaveTitle"
import wavyDivorce from '../assets/icons/icon-wavy-divorce.svg';
import FeaturedArticlesGrid from "../components/articles/FeaturedArticleGrid"

const ArticlePage = ({ data }) => {
  const article = data.strapiArticle

  const seo = {
    metaTitle: article.title,
    metaDescription: article.title,
    shareImage: article?.cover?.url,
    article: true
  }

  return (
    <Layout as="article">
      <Seo seo={seo} />
      <main className="">
        <section id="safe-harbor-introduction" className="no-padding-bottom">
          {/* <div className="container small flex center">  
            {
              article?.cover?.url !== undefined && <img className="w-50" src={`${api_url}${article.cover.url}`} alt="" />
            }
          </div> */}
        </section>
        <section id="safe-harbor-article-content">
          <div className="container smaller">
            <div className="flex column items-start gap-small">
              <img src={wavyDivorce} alt="" style={{maxWidth: "30%"}}/>
              <h1 className="w-100">{article.title}</h1>
              <p className="medium grey-light bold text-center mt-1">
                By {article.author.name}
              </p>
            </div>
            <div className="mt-10">
              <BlocksRenderer blocks={article.blocks || []} />
            </div>
          </div>
        </section>
        <section>
          <WaveTitle title="More" />
          <div className="container">
            <FeaturedArticlesGrid excludeSlug={article.slug} />
          </div>
          <div className="flex center mt-5">
            <Link className="btn secondary" to="/dive-deeper">View All</Link>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      author {
        name
      }
      blocks {
        ...Blocks
      }
      cover {
        url          
      }
    }
  }
`

export default ArticlePage
