import React from "react"
import { graphql } from "gatsby"
import BlockRichText from "./BlockRichText"
import BlockMedia from "./BlockMedia"
import BlockQuote from "./BlockQuote"
import BlockSlider from "./BlockSlider"

const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  // STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  // STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  console.log(block)

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => {
  return (
    <div className="blocks">
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </div>
  )
}

// export const query = graphql`
// fragment Blocks on STRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_RICH_TEXTUnion {
//   __typename
//   ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
//     richTextBody: body {
//       __typename
//       data {
//         id
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
//   ... on STRAPI__COMPONENT_SHARED_MEDIA {
//     file {
//       mime
//       url
//     }
//   } 
// }
// `

export const query = graphql`
fragment Blocks on STRAPI__COMPONENT_SHARED_RICH_TEXT {
  __typename
  ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
    richTextBody: body {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default BlocksRenderer
