import React from "react"
import formatImgSrc from "../../helpers"

const BlockRichText = ({ data }) => {
  return (
      <div
        className="block-text black medium"
        dangerouslySetInnerHTML={{
          __html: formatImgSrc(data.richTextBody.data.childMarkdownRemark.html),
        }}
      />
  )
}

export default BlockRichText
